import React, {useMemo, useState, useEffect} from 'react';
import {
    DoorPreview,
    DrawerRunner,
    GenericFields,
    MaterialDefaults,
    Sizes,
    DrawerPreview,
    DoorSubPanels,
    RailPositions,
    PartitionHeights,
    DrawerFaces,
    DoorDrillings,
    Shelves,
    ShelvesPreview,
    VerticalPartition,
} from 'components/customer/Product';
import {useProductContext, useAppContext} from 'contexts';
import {Col, Container, Row} from 'react-bootstrap';
import excel from 'shared/Excel';
import {useFormikContext} from 'formik';
import {DrillingPreview} from './Preview/DrillingPreview';
import {Drillings} from './Specs/Drillings';
import {Loader, getHingeDirection, getCabinetFields} from 'shared/helpers';
import {SizePreview} from './Preview/SizePreview';
import CollapsibleHeader, {CancelButton} from './CollapsibleHeader';
import {
    isDeviceMediumToLarge,
    useTabletSize,
    isDeviceSmall,
} from 'shared/helpers/DeviceSize';
import {MidRails} from './Specs/MidRails';
import NoPreview3DPlaceholder from 'components/customer/Preview3DCommon/components/NoPreview3DPlaceholder';
import {Sticky} from 'shared/components/Sticky';
import {DrawerEdges} from 'components/customer/Product/Specs/DrawerEdges';
import {checkCurrentMaterialAdvanced} from 'components/customer/Product/helpers/checkCurrentMaterialAdvanced';
import {useAppSelector} from 'store/customer';
import {getHasWebGLError} from 'components/customer/Preview3DCommon/store/viewerSlice';
import {ProductionOptions} from './Specs/ProductionOptions';
import {Preview3DComponentLazy} from '../Preview3DCommon/components/Preview3DComponentLazy';
import {CurrentInnerDrawerRunner} from 'components/customer/Product/Specs/CurrentInnerDrawerRunner';

export const FIELDSET_LAYOUT = {
    COMPOUND: 'compound',
    SHELVES: 'shelves',
    DRILLINGS: 'panel_shelf_drillings',
    DOOR_DRILLINGS: 'door_drillings',
    MID_RAILS: 'mid_rails',
    DRAWER_EDGES: 'drawer_panel_edges',
};

export const FIELDSETS = {
    GENERIC: 'generic',
    SIZES: 'sizes',
    COMMENTS: 'comments',
    ADDITIONAL: 'additional',
    MATERIALS: 'materials',
    DOOR_FIELDS: 'door_fields',
    MARGIN_FIELDS: 'margin_fields',
    BORDER_WIDTHS: 'border_widths',
    DOOR_EDGES: 'door_edges',
    DRAWER_EDGES: 'drawer_panel_edges',
    DRAWER_MARGINS: 'drawer_margins',
    DRAWER_RUNNERS: 'drawer_runners',
    CURRENT_INNER_DRAWER_RUNNERS: 'inner_drawer_runners',
    MID_RAILS: 'mid_rails',
    HORI_MID_RAILS: 'hori_mid_rail_positions',
    VERT_MID_RAILS: 'vert_mid_rail_positions',
    SUB_PANELS: 'advanced_door_glass',
    SHELVES: 'shelves',
    UPPER_SHEPVES: 'upper_shelves',
    MIDDLE_SHELVES: 'middle_shelves',
    LOWER_SHELVES: 'lower_shelves',
    VERTICAL_SHELVES_POSITION: 'vertical_partition',
    DRILLINGS: 'panel_shelf_drillings',
    EDGINGS: 'cabinet_edges',
    PARTITION_HEIGHTS: 'partition_heights',
    DRAWER_FACES: 'drawer_face_heights',
    DOOR_DRILLINGS: 'door_drillings',
    APPLIANCE: 'appliance',
    CABINET_INCLUDE_HARDWARE: 'cabinet_include_hardware',
    SIMPLE_SHELVES: 'simple_shelves',
    DRAWER_FIELDS: 'drawer_fields',
    MATERIAL_OPTIONS: 'material_options',
    VARIATIONS: 'variations',
    SHELF_ADVANCED_CHECKBOX: 'shelf_advanced_checkbox',
    FINGERPULL: 'fingerpull',
    ADDITIONAL_SIZES: 'additional_sizes',
    RANGEHOOD: 'rangehood',
    OVEN: 'oven',
    MICROWAVE: 'microwave',
};

const FieldsetStrategy = {
    [FIELDSETS.SIZES]: {
        render(fields, fieldset, name, layout, title, hasPreview) {
            return <Sizes fields={fields} hasPreview={hasPreview} />;
        },
    },
    [FIELDSETS.MATERIALS]: {
        render(fields, fieldset, name, layout, title, hasPreview) {
            return <MaterialDefaults fields={fields} hasPreview={hasPreview} />;
        },
    },
    [FIELDSETS.GENERIC]: {
        render(
            fields,
            fieldset,
            fieldsetName,
            fieldsetLayout,
            fieldsetTitle,
            preview,
            cancelButton
        ) {
            return (
                <GenericFields
                    fields={fields}
                    fieldset={fieldset}
                    fieldsetName={fieldsetName}
                    fieldsetLayout={fieldsetLayout}
                    fieldsetTitle={fieldsetTitle}
                    preview={preview}
                    cancelButton={cancelButton}
                />
            );
        },
    },
    [FIELDSETS.DRAWER_RUNNERS]: {
        render(fields, fieldset) {
            return <DrawerRunner field={fields[0]} fieldset={fieldset} />;
        },
    },

    [FIELDSETS.CURRENT_INNER_DRAWER_RUNNERS]: {
        render(fields, fieldset) {
            return (
                <CurrentInnerDrawerRunner
                    field={fields[0]}
                    fieldset={fieldset}
                />
            );
        },
    },
    [FIELDSETS.SUB_PANELS]: {
        render(fields, fieldset) {
            return <DoorSubPanels fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.HORI_MID_RAILS]: {
        render(fields, fieldset) {
            return <RailPositions fieldset={fieldset} fieldName="rail_hori" />;
        },
    },
    [FIELDSETS.VERT_MID_RAILS]: {
        render(fields, fieldset) {
            return <RailPositions fieldset={fieldset} fieldName="rail_vert" />;
        },
    },
    [FIELDSETS.VERTICAL_SHELVES_POSITION]: {
        render(
            fields,
            fieldset,
            fieldsetName,
            fieldsetLayout,
            fieldsetTitle,
            preview
        ) {
            return (
                <VerticalPartition
                    fields={fields}
                    fieldsetName={fieldsetName}
                    fieldsetLayout={fieldsetLayout}
                    fieldsetTitle={fieldsetTitle}
                    preview={preview}
                />
            );
        },
    },
    [FIELDSETS.SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.UPPER_SHEPVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.MIDDLE_SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.LOWER_SHELVES]: {
        render(fields, fieldset) {
            return <Shelves fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRILLINGS]: {
        render(fields, fieldset) {
            return <Drillings fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.PARTITION_HEIGHTS]: {
        render(fields, fieldset) {
            return <PartitionHeights fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRAWER_EDGES]: {
        render(fields, fieldset) {
            return <DrawerEdges fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DRAWER_FACES]: {
        render(fields, fieldset) {
            return <DrawerFaces fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.DOOR_DRILLINGS]: {
        render(fields, fieldset) {
            return <DoorDrillings fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.MID_RAILS]: {
        render(fields, fieldset) {
            return <MidRails fields={fields} fieldset={fieldset} />;
        },
    },
    [FIELDSETS.ADDITIONAL]: {
        render(fields, fieldset, fieldsetName) {
            return (
                <ProductionOptions
                    fields={fields}
                    fieldset={fieldset}
                    fieldsetName={fieldsetName}
                />
            );
        },
    },
};

const FieldSet = ({
    fieldset,
    hasPreview,
    index,
    values,
    showFieldsetTitle = true,
    setFieldValue,
}) => {
    let collapsible = false;
    let defaultDisplay = true;

    if (
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('collapsible') &&
        fieldset.options.collapsible
    ) {
        collapsible = true;
        defaultDisplay = false; // Collapsed by default

        if (fieldset.options.hasOwnProperty('collapsed')) {
            if (typeof fieldset.options.collapsed == 'string') {
                defaultDisplay = () => {
                    try {
                        return !excel.calculate(
                            fieldset.options.collapsed,
                            values
                        );
                    } catch (e) {
                        return false;
                    }
                };
            } else {
                defaultDisplay = !fieldset.options.collapsed;
            }
        }
    }

    const [displayFieldset, setDisplayFieldset] = useState(defaultDisplay);

    const clickHandler = () => {
        if (collapsible) {
            setDisplayFieldset(!displayFieldset);
        }
    };

    let fieldsetType = FieldsetStrategy[fieldset.name];
    let fieldsetLayout =
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('layout') &&
        fieldset.options.layout;
    let hideHorizontalRule = false;

    if (typeof fieldsetType === 'undefined') {
        fieldsetType = FieldsetStrategy[FIELDSETS.GENERIC];
    }

    if (Object.values(FIELDSET_LAYOUT).includes(fieldsetLayout)) {
        showFieldsetTitle = false;
    }

    // exceptional conditions
    if (
        fieldset.name === 'hori_mid_rail_positions' ||
        fieldset.name === 'vert_mid_rail_positions' ||
        fieldset.name === FIELDSETS.UPPER_SHEPVES ||
        fieldset.name === FIELDSETS.MIDDLE_SHELVES ||
        fieldset.name === FIELDSETS.LOWER_SHELVES
    ) {
        hideHorizontalRule = true;
    }

    if (
        fieldset.name === FIELDSETS.DRAWER_RUNNERS &&
        fieldset.hasOwnProperty('options') &&
        fieldset.options.hasOwnProperty('layout') &&
        fieldset.options.layout === 'drawer'
    ) {
        showFieldsetTitle = false;
    }

    let fieldsetContent = <></>;
    let cancelButton = null;
    const hasAddField = fieldset.options?.layout == 'add' && collapsible;

    if (hasAddField)
        cancelButton = (
            <CancelButton
                toggleIsOpen={() => setDisplayFieldset(!displayFieldset)}
            />
        );

    if (displayFieldset) {
        fieldsetContent = fieldsetType.render(
            fieldset.fields,
            fieldset,
            fieldset.name,
            fieldsetLayout,
            fieldset.hasOwnProperty('title') && fieldset.title,
            hasPreview,
            cancelButton
        );
    } else {
        fieldsetContent = <></>;
    }

    useEffect(() => {
        if (!displayFieldset && hasAddField) {
            fieldset?.fields?.forEach((field) => {
                setFieldValue(field.name, field.value);
            });
        }
    }, [displayFieldset]);

    return (
        <section
            id={`${fieldset.name}-id`}
            className={displayFieldset ? 'expanded' : 'collapsed'}>
            {!showFieldsetTitle && index !== 0 ? (
                !hideHorizontalRule ? (
                    <hr />
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}

            {showFieldsetTitle &&
            fieldset.hasOwnProperty('title') &&
            fieldset.title ? (
                hasAddField ? (
                    <CollapsibleHeader
                        title={fieldset.title}
                        collapsible={collapsible}
                        onClick={clickHandler}
                        isOpen={displayFieldset}
                    />
                ) : (
                    <h3
                        className="notes-and-variations"
                        style={{
                            userSelect: 'none',
                        }}
                        onClick={clickHandler}>
                        <span>{fieldset.title} </span>
                    </h3>
                )
            ) : (
                <></>
            )}
            {fieldsetContent}
        </section>
    );
};

export const Specs = ({
    title,
    preview = 'standard',
    fieldsets,
    isQFP = false,
    structure,
    className = null,
}) => {
    const {
        productDataStore,
        getMaterialOptions,
        isLoading,
        formStructure,
        currentTab,
    } = useProductContext();
    const {values, setFieldValue} = useFormikContext();
    const mediumToLargeDevice = isDeviceMediumToLarge();
    const isTabletSize = useTabletSize();
    const isSmallDevice = isDeviceSmall();

    const {userProfile} = useAppContext();
    const allow3DView = userProfile?.allow3DView;

    const has3DPreview =
        productDataStore.current?.template_3d &&
        productDataStore.current?.template_3d?.length > 0 &&
        allow3DView;
    const hasWebGLError = useAppSelector(getHasWebGLError);

    const [panelPreview, hasPreview] = useMemo(() => {
        let previewContent = <></>;
        let hasPreview = false;
        const cabinetInfo = getCabinetFields(formStructure);
        const materialOptions = getMaterialOptions();

        switch (preview) {
            case 'edging':
                let cabinetCode;
                let doorHang;

                if (
                    productDataStore.current.hasOwnProperty('cabinet') &&
                    productDataStore.current.cabinet.hasOwnProperty(
                        'attributes'
                    )
                ) {
                    if (
                        productDataStore.current.cabinet.attributes.hasOwnProperty(
                            'code'
                        )
                    ) {
                        cabinetCode =
                            productDataStore.current.cabinet.attributes.code;
                    }
                    if (
                        productDataStore.current.cabinet.attributes.hasOwnProperty(
                            'door_hang'
                        )
                    ) {
                        doorHang =
                            productDataStore.current.cabinet.attributes.door_hang.toLowerCase();
                    }
                }

                const isProductDoor =
                    !isQFP &&
                    (cabinetInfo.hasExteriorDoor || cabinetCode == 'Door');

                if (isProductDoor) {
                    if (
                        !doorHang.includes('bifold') &&
                        !doorHang.includes('rollup')
                    ) {
                        const hingeDirection = getHingeDirection(structure);

                        previewContent = (
                            <Col sm={isTabletSize ? 12 : 6} id="canvas-wrapper">
                                <Sticky>
                                    <DoorPreview
                                        hingeDirection={hingeDirection}
                                        isQFP={isQFP}
                                        has3DPreview={has3DPreview}
                                    />
                                </Sticky>
                            </Col>
                        );
                        hasPreview = true;
                    } else {
                        previewContent = <Col sm={5} id="canvas-wrapper"></Col>;
                    }
                }
                break;

            case 'standard':
                if (
                    structure.hasOwnProperty('canvasPreview') &&
                    structure.canvasPreview
                ) {
                    previewContent = (
                        <Col
                            sm={mediumToLargeDevice ? 5 : isTabletSize ? 12 : 6}
                            style={{paddingLeft: isTabletSize ? 15 : 25}}>
                            <SizePreview />
                        </Col>
                    );
                    hasPreview = true;
                }
                break;

            case 'panel_shelf_drillings':
                previewContent = (
                    <Col sm={isTabletSize ? 12 : 4} id="canvas-wrapper">
                        <DrillingPreview />
                    </Col>
                );
                hasPreview = true;
                break;
        }

        if (!isQFP && title === 'Materials') {
            const fieldsetNames = fieldsets.map((fieldset) => fieldset.name);
            const hingeDirection = getHingeDirection(structure);

            if (
                fieldsetNames.includes('door_edges') ||
                fieldsetNames.includes('drawer_panel_edges')
            ) {
                previewContent = (
                    <Col sm={isTabletSize ? 12 : 4} id="canvas-wrapper">
                        <Sticky>
                            <DoorPreview
                                appliedPanel={true}
                                hingeDirection={hingeDirection}
                                isQFP={isQFP}
                            />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            }
        }

        const isAdvancedDoor = checkCurrentMaterialAdvanced(
            productDataStore,
            materialOptions
        );

        if (!isQFP && title === 'Drawers') {
            previewContent = (
                <Col
                    xs={isSmallDevice ? 12 : undefined}
                    sm={isTabletSize ? 12 : undefined}
                    id="canvas-wrapper">
                    <Sticky>
                        <DrawerPreview
                            isAdvanced={isAdvancedDoor}
                            has3DPreview={has3DPreview}
                        />
                    </Sticky>
                </Col>
            );
            hasPreview = true;
        }

        if ((!isQFP && title === 'Edging') || title === 'Faces') {
            previewContent = (
                <Col sm={isTabletSize ? 12 : 6} id="canvas-wrapper">
                    <SizePreview showEdges={true} />
                </Col>
            );
            hasPreview = true;
        }

        if (!isQFP && title === 'Shelves') {
            if (
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .indexOf('door') > -1 &&
                productDataStore.current.cabinet.attributes.original_name
                    .toLowerCase()
                    .indexOf('drawer') > -1
            ) {
                previewContent = (
                    <Col sm={isTabletSize ? 12 : 5} id="canvas-wrapper">
                        <Sticky>
                            <ShelvesPreview
                                previewOptions={structure.preview_options}
                            />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            } else {
                let previewOptions;
                if (structure.hasOwnProperty('preview_options')) {
                    previewOptions = structure.preview_options;
                }

                previewContent = (
                    <Col sm={isTabletSize ? 12 : 5} id="canvas-wrapper">
                        <Sticky>
                            <ShelvesPreview previewOptions={previewOptions} />
                        </Sticky>
                    </Col>
                );
                hasPreview = true;
            }
        }

        return [previewContent, hasPreview];
    }, [preview, formStructure, currentTab]);

    const filteredFieldsets = fieldsets.filter((fieldset) => {
        if (
            fieldset.hasOwnProperty('options') &&
            fieldset.options.hasOwnProperty('visible')
        ) {
            let visibleCondition = fieldset.options.visible;

            if (
                !isNaN(visibleCondition) &&
                typeof visibleCondition !== 'boolean'
            ) {
                visibleCondition = parseInt(visibleCondition) > 0;
            }

            if (
                isNaN(visibleCondition) &&
                typeof visibleCondition === 'string'
            ) {
                try {
                    visibleCondition = excel.calculate(
                        visibleCondition,
                        values
                    );
                } catch (error) {
                    visibleCondition = fieldset.options.visible;
                }
            }

            if (typeof visibleCondition === 'boolean') {
                if (!visibleCondition) return false;
            } else {
                const isVisible =
                    !isQFP && getMaterialOptions()?.cabinet_door?.advanced;

                if (!isVisible) return false;
            }
        }

        return true;
    });

    const checkCustomDoorHang = (doorHangName) => {
        const doorFields = fieldsets.find(
            (fieldset) => fieldset.name === 'door_fields'
        );

        if (doorFields) {
            const doorHang = doorFields?.fields?.find(
                (doorField) => doorField.name === 'door_hang'
            );

            if (doorHang) {
                return (
                    doorHang.value?.toLowerCase()?.indexOf(doorHangName) > -1
                );
            }
        }

        return false;
    };

    const hasBifoldDoor = useMemo(() => {
        return checkCustomDoorHang('bifold');
    }, [fieldsets]);

    const hasRollUpDoor = useMemo(() => {
        return checkCustomDoorHang('rollup');
    }, [fieldsets]);

    const previewLocation = useMemo(() => {
        let location = 'SPECS';

        if (fieldsets.find((fieldset) => fieldset.name === 'drawer_margins')) {
            location = 'DRAWERS';
        }

        if (
            fieldsets.find((fieldset) =>
                ['simple_shelves', 'shelves', 'upper_shelves'].includes(
                    fieldset.name
                )
            )
        ) {
            location = 'SHELVES';
        }

        if (hasBifoldDoor || hasRollUpDoor) {
            location = 'DOORS';
        }

        return location;
    }, [fieldsets, hasBifoldDoor, hasRollUpDoor]);

    const renderFieldSet = (fieldset, index) => {
        return (
            <FieldSet
                key={index}
                index={index}
                hasPreview={hasPreview || has3DPreview}
                preview={preview}
                fieldset={fieldset}
                values={values}
                showFieldsetTitle={fieldset.name !== FIELDSETS.MATERIALS}
                setFieldValue={setFieldValue}
            />
        );
    };

    const isSpecsTab = fieldsets.find((fieldset) => fieldset.name === 'sizes');

    const panel3DPreview = useMemo(() => {
        if (!isSpecsTab && !has3DPreview) return panelPreview;
        if ((isSpecsTab && !allow3DView) || hasWebGLError) return panelPreview;

        return (
            <Col
                xs={isSmallDevice ? 12 : undefined}
                sm={isTabletSize ? 12 : undefined}>
                <Sticky>
                    {has3DPreview ? (
                        previewLocation === 'SPECS' ? (
                            <>
                                <Preview3DComponentLazy
                                    previewLocation={previewLocation}
                                />
                                <Preview3DComponentLazy
                                    previewLocation={previewLocation}
                                    hiddenForScreenshot
                                />
                            </>
                        ) : (
                            <Preview3DComponentLazy
                                previewLocation={previewLocation}
                            />
                        )
                    ) : (
                        <NoPreview3DPlaceholder />
                    )}
                </Sticky>
            </Col>
        );
    }, [
        previewLocation,
        isSmallDevice,
        isTabletSize,
        panelPreview,
        allow3DView,
        has3DPreview,
        isSpecsTab,
        hasWebGLError,
    ]);

    let drawerRunners = null;
    let additionals = null;
    const drawerRunnersFilter = (fieldset) =>
        ['drawer_runners', 'inner_drawer_runners'].includes(fieldset.name);
    const additionalFilter = (fieldset) =>
        fieldset.name == 'additional' || fieldset.name == 'variations';

    if (title === 'Drawers')
        drawerRunners = filteredFieldsets.filter(drawerRunnersFilter);

    if (isSpecsTab) additionals = filteredFieldsets.filter(additionalFilter);

    const isMaterialTab = fieldsets.find(
        (fieldset) => fieldset.name === 'materials'
    );

    const show3DPreview =
        fieldsets.find((fieldset) =>
            [
                'sizes',
                'simple_shelves',
                'upper_shelves',
                'shelves',
                'winerack_hori',
            ].includes(fieldset.name)
        ) ||
        hasBifoldDoor ||
        hasRollUpDoor;

    return (
        <Loader loader={isLoading}>
            <Container className={className == null ? '' : className}>
                <Row>
                    <Col md={isMaterialTab ? 12 : undefined}>
                        {filteredFieldsets
                            .filter(
                                (fieldset) => !drawerRunnersFilter(fieldset)
                            )
                            .filter((fieldset) => !additionalFilter(fieldset))
                            .map(renderFieldSet)}
                    </Col>
                    {show3DPreview ? panel3DPreview : panelPreview}
                </Row>
                {drawerRunners && (
                    <>
                        <hr />
                        <Row>
                            <Col xs={12}>
                                {drawerRunners.map(renderFieldSet)}
                            </Col>
                        </Row>
                    </>
                )}
                {additionals && (
                    <Row style={{marginTop: 20}}>
                        <Col xs={12}>{additionals.map(renderFieldSet)}</Col>
                    </Row>
                )}
            </Container>
        </Loader>
    );
};
