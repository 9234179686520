import {CurrentInnerDrawer} from 'components/customer/Product/entity';

export const calculateDrawerPositions = (
    availableHeight: number,
    drawers: CurrentInnerDrawer[]
) => {
    const positions: CurrentInnerDrawer[] = [];

    let isInvalid = false;
    let errorMessage = '';

    // Calculate total required height for drawers and clearances
    const totalRequiredHeight = drawers
        .slice()
        .reverse()
        .reduce((acc, drawer, index) => {
            const topClearance = drawer.top_clearance || 0;
            const bottomClearance =
                index === 0 ? drawer.inner_bottom_clearance || 0 : 0;
            return acc + drawer.drawer_height + topClearance + bottomClearance;
        }, 0);

    if (totalRequiredHeight > availableHeight) {
        isInvalid = true;
        errorMessage = 'Total drawers do not fit in the cabinet.';
    }

    drawers
        .slice()
        .reverse()
        .forEach((drawer, index) => {
            let positionToBottom = drawer.position_to_bottom;
            let maxPosition = availableHeight;
            let minPosition = 0;

            // last drawer
            if (index === drawers.length - 1) {
                maxPosition = Math.min(
                    maxPosition,
                    availableHeight - drawer.drawer_height
                );
            }

            if (index > 0) {
                const prevDrawer = positions[index - 1];
                minPosition =
                    prevDrawer.position_to_bottom +
                    prevDrawer.drawer_height +
                    prevDrawer.top_clearance;

                minPosition = Math.min(
                    minPosition,
                    availableHeight - drawer.drawer_height
                );

                positionToBottom = minPosition;
            }

            // Calculate maxPosition based on the remaining drawers
            for (let i = index + 1; i < drawers.length; i++) {
                const nextDrawer = drawers[Number(i)];
                maxPosition -=
                    nextDrawer.drawer_height + nextDrawer.top_clearance;
            }

            // Validate the current position_to_bottom
            if (
                positionToBottom < minPosition ||
                positionToBottom > maxPosition
            ) {
                // Adjust if the current value is out of bounds
                positionToBottom = Math.max(
                    minPosition,
                    Math.min(positionToBottom, maxPosition)
                );
            }

            // If this is the first drawer, ensure it respects its inner_bottom_clearance
            if (index == 0) {
                minPosition = drawer.inner_bottom_clearance;
                positionToBottom = minPosition;
            }

            const postionToBottomValue =
                drawer?.manualPositionToBottom || positionToBottom;

            if (!isInvalid) {
                isInvalid =
                    postionToBottomValue < minPosition ||
                    postionToBottomValue > maxPosition;
            }

            positions.push({
                position_to_bottom: postionToBottomValue,
                drawer_height: drawer.drawer_height,
                top_clearance: drawer.top_clearance,
                index: drawer.index,
                isInvalid,
                max_position: maxPosition,
                min_position: minPosition,
                errorMessage,
            });
        });

    return {
        positions,
        totalRequiredHeight,
    };
};
