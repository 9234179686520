import {useState, useEffect, useMemo, useRef} from 'react';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import {isEqual, toNumber} from 'lodash';
import type {
    Field as FieldType,
    DrawerFieldset as Fieldset,
    MaterialOptions,
} from 'shared/types';
import {CurrentInnerDrawer} from 'components/customer/Product/entity';
import {
    DrawerRunnerRequest,
    useGetDrawerSystemQuery,
    useLazyGetRunnerQuery,
} from 'components/customer/Product/store/drawerRunnerApi';
import {
    CurrentInnerDrawerFields,
    InnerDrawerKey,
} from 'components/customer/Product/entity/Drawer';
import {useSearchParams} from 'react-router-dom';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';
import {calculateDrawerPositions} from 'components/customer/Product/InnerDrawer/helpers/calculateDrawerPositions';
import {v4 as uuidv4} from 'uuid';
interface InnerDrawerParameters {
    quantity?: number;
    drawerRunnerFieldName?: string;
    drawerTypeFieldName?: string;
    drawerGapFieldName?: string;
    drawerTopMarginFieldName?: string;
    drawerBottomMarginFieldName?: string;
    totalDrawerHeight?: string;
    totalRequiredHeight?: number;
    totalAvailableHeight?: number;
}

export const useInnerRunnerRequestParams = (
    runnerIndex: number,
    drawerTypeFieldName: string
) => {
    const {values} = useFormikContext<CurrentInnerDrawerFields>();
    const [searchParams] = useSearchParams();

    const [params, setParams] = useState<DrawerRunnerRequest>({});

    const drawerRunnerProps = useRef<DrawerRunnerRequest>({});
    const getInnerDrawerRunnerProps = (): DrawerRunnerRequest => {
        const drawer = values.current_inner_drawers[Number(runnerIndex)];

        return {
            insertType: toNumber(drawer[drawerTypeFieldName as InnerDrawerKey]),
            cabinetId: parseInt(searchParams.get('product')),
        };
    };

    useEffect(() => {
        const runnerProps = getInnerDrawerRunnerProps();

        if (!isEqual(runnerProps, drawerRunnerProps.current)) {
            drawerRunnerProps.current = runnerProps;

            setParams(runnerProps);
        }
    }, [values]);

    return {
        params,
    };
};

export const useCurrentInnerDrawer = ({
    field,
    fieldset,
}: {
    field: FieldType;
    fieldset: Fieldset;
}) => {
    const [runners, setRunners] = useState<DrawerRunner[]>([]);
    const [getRunners, {isFetching, isLoading}] = useLazyGetRunnerQuery();

    const {values, setFieldValue} = useFormikContext<{
        current_inner_drawer_amount: number;
        current_inner_drawers: CurrentInnerDrawer[];
        cabinet_height: number;
        [key: string]: number | CurrentInnerDrawer[];
    }>();

    const {getMaterialOptions} = useProductContext<{
        getMaterialOptions: () => MaterialOptions;
    }>();
    const materialOptions = getMaterialOptions();

    const {getAvailableInnerDrawers} = useProductContext<{
        getAvailableInnerDrawers: () => number[];
    }>();
    const {data: drawerSystems} = useGetDrawerSystemQuery();
    const availableInnerDrawerSystems = useMemo(() => {
        if (drawerSystems) {
            const availableInnerDrawers = getAvailableInnerDrawers();
            const isDrillOnly = values.cabinet_include_hardware === 0;

            return drawerSystems
                .filter((drawerSystem) =>
                    availableInnerDrawers.includes(drawerSystem.id)
                )
                .map((drawerSystem) => ({
                    ...drawerSystem,
                    disabled: !isDrillOnly && drawerSystem.drillOnly,
                }));
        }
        return [];
    }, [drawerSystems, values.cabinet_include_hardware]);

    const drawerParams = useMemo<InnerDrawerParameters>(() => {
        const params: InnerDrawerParameters = {
            drawerGapFieldName: '',
            totalDrawerHeight: '',
            drawerTopMarginFieldName: 'cabinet_drawer_top',
            drawerBottomMarginFieldName: 'cabinet_drawer_bottom',
        };

        if (field) {
            params.drawerRunnerFieldName = field.name;
            params.drawerTypeFieldName = field.options.drawerTypeFieldName;
        }

        if (fieldset && fieldset.quantity) {
            params.quantity = values.current_inner_drawer_amount;
            params.drawerGapFieldName = fieldset.options.drawerGap;
            params.totalDrawerHeight = fieldset.options.totalDrawerHeight;
            params.totalAvailableHeight =
                values.cabinet_height -
                materialOptions.cabinet_carc_colour.thickness * 2;
        }

        return params;
    }, [field, fieldset, values]);

    const insertId = availableInnerDrawerSystems[0].id;

    const [searchParams] = useSearchParams();

    const triggerGetRunners = async (runnerProps: DrawerRunnerRequest) => {
        const {data} = await getRunners(runnerProps, true);
        const filteredData = data?.filter((runner) => runner.innerUnitCost > 0);
        setRunners(filteredData);
    };

    useEffect(() => {
        if (insertId && searchParams.get('product')) {
            void triggerGetRunners({
                insertType: toNumber(insertId),
                cabinetId: parseInt(searchParams.get('product')),
            });
        }
    }, [insertId, searchParams.get('product')]);

    const currentDrawerSystem = useMemo(() => {
        return availableInnerDrawerSystems?.find(
            (option) => option.id == insertId
        );
    }, [availableInnerDrawerSystems]);

    const recalculateAndHandleErrors = (
        drawers: CurrentInnerDrawer[],
        runners: DrawerRunner[],
        counter = 0
    ): CurrentInnerDrawer[] => {
        const calc = calculateDrawerPositions(
            drawerParams.totalAvailableHeight,
            drawers
        );

        const hasErrors = calc.positions.some(
            (position) => position.errorMessage
        );

        if (hasErrors) {
            if (counter >= runners.length) {
                return drawers;
            }

            const runner = runners[Number(counter)];
            const updatedDrawers = drawers.map((drawer) => {
                return {
                    ...drawer,
                    drawer_height: runner.runnerHeight,
                    inner_drawer_runner_specs: runner.id,
                };
            });

            return recalculateAndHandleErrors(
                updatedDrawers,
                runners,
                counter + 1
            );
        }

        return drawers;
    };

    useEffect(() => {
        if (!runners || runners.length === 0) {
            return;
        }

        let updatedDrawers = [...values.current_inner_drawers];

        const runner = runners?.find(
            (option) => option.id === runners?.[0]?.id
        );

        if (updatedDrawers.length < drawerParams.quantity) {
            while (updatedDrawers.length < drawerParams.quantity) {
                const innerDrawerBottomClearance =
                    currentDrawerSystem?.innerBottomClearance || 0;
                const topClearance = currentDrawerSystem?.topClearance || 0;
                const drawerHeight = runner?.runnerHeight || 0;
                const innerRunner = runner?.id || '';

                updatedDrawers.push({
                    index: uuidv4().slice(0, 3),
                    position_to_bottom: 0,
                    inner_drawer_runner_specs: innerRunner,
                    insert_id: insertId,
                    inner_bottom_clearance: innerDrawerBottomClearance,
                    top_clearance: topClearance,
                    drawer_height: drawerHeight,
                });
            }
        } else if (updatedDrawers.length > drawerParams.quantity) {
            updatedDrawers = updatedDrawers.slice(0, drawerParams.quantity);
        }

        if (updatedDrawers.length !== values.current_inner_drawers.length) {
            updatedDrawers = recalculateAndHandleErrors(
                updatedDrawers,
                runners
            );

            void setFieldValue('current_inner_drawers', updatedDrawers);
        }
    }, [runners, drawerParams.quantity, values.current_inner_drawers.length]);

    return {
        runners,
        isFetching,
        isLoading,
        availableInnerDrawerSystems,
        drawerParams,
        values,
        setFieldValue,
    };
};
