import React, {useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import {DrawerParameters} from 'components/customer/Product/entity/DrawerParameters';
import {InnerDrawerType} from 'components/customer/Product/InnerDrawer/InnerDrawerType';
import {CurrentInnerDrawerRunner} from 'components/customer/Product/InnerDrawer/CurrentInnerDrawerRunner';
import {CurrentInnerDrawerPositionToBottom} from 'components/customer/Product/InnerDrawer/CurrentInnerDrawerPositionToBottom';
import {DrawerSystem} from '../entity';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';
import {useNotificationContext} from 'contexts';
import {validationErrorAppliesToField} from 'shared/validator';
// import {CurrentInnerRunnerPreview} from 'components/customer/Product/InnerDrawer/CurrentInnerRunnerPreview';

interface DrawerTypeProps {
    runnerIndex: number;
    showLine: boolean;
    drawerParams: DrawerParameters;
    availableInnerDrawerSystems: DrawerSystem[];
    runners: DrawerRunner[];
    isLoading: boolean;
    isFetching: boolean;
}

export const CurrentInnerDrawer = ({
    runnerIndex,
    showLine = true,
    // drawerParams,
    availableInnerDrawerSystems,
    runners,
    isLoading,
    isFetching,
}: DrawerTypeProps): JSX.Element => {
    const {messages} = useNotificationContext();

    const errorMessage = useMemo(() => {
        const errors = messages?.errors;

        if (errors && Array.isArray(errors)) {
            const runnerErrors = errors.filter((error) =>
                validationErrorAppliesToField(
                    error,
                    'inner_drawer_runner_specs',
                    runnerIndex
                )
            );

            return runnerErrors.find(({fields}) =>
                fields.includes(`inner_drawer_runner_specs[${runnerIndex}]`)
            )?.message;
        }
        return null;
    }, [messages, runnerIndex]);

    return (
        <>
            <Row className="drawer-runner">
                <Col md={7} xs={12}>
                    <strong className="compound_title">
                        Drawer #{runnerIndex + 1}
                    </strong>
                    <InnerDrawerType
                        fieldName={`current_inner_drawers[${runnerIndex}].insert_id`}
                        availableInnerDrawerSystems={
                            availableInnerDrawerSystems
                        }
                    />
                    <CurrentInnerDrawerRunner
                        errorMessage={errorMessage}
                        runnerName={`current_inner_drawers[${runnerIndex}].inner_drawer_runner_specs`}
                        runners={runners}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        runnerIndex={runnerIndex}
                    />
                    <CurrentInnerDrawerPositionToBottom
                        label="Position to Bottom"
                        name={`position_to_bottom`}
                        runnerIndex={runnerIndex}
                    />
                </Col>
                {/* <Col md={2} xs={6}>
                    <CurrentInnerRunnerPreview
                        runnerIndex={runnerIndex}
                        totalDrawerHeight={drawerParams.totalDrawerHeight}
                        drawerGapFieldName={drawerParams.drawerGapFieldName}
                        drawerTopMarginFieldName={
                            drawerParams.drawerTopMarginFieldName
                        }
                        drawerBottomMarginFieldName={
                            drawerParams.drawerBottomMarginFieldName
                        }
                    />
                </Col> */}
                {/* <Col md={3} xs={6}>
                    <section className="drawer-thumbnail">
                        <Image src={`${currentRunner?.image}`} rounded />
                    </section>
                </Col> */}
            </Row>
            {showLine ? <hr /> : null}
        </>
    );
};
