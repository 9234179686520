import React, {useEffect, useMemo, useRef} from 'react';

import type {
    Field as FieldType,
    DrawerFieldset as Fieldset,
} from 'shared/types';
import {CurrentInnerDrawer as InnerDrawer} from 'components/customer/Product/InnerDrawer/CurrentInnerDrawer';
import {CurrentInnerDrawer} from 'components/customer/Product/entity';
import {useCurrentInnerDrawer} from 'components/customer/Product/InnerDrawer/helpers/useCurrentInnerDrawer';
import {isEqual} from 'lodash';
import {calculateDrawerPositions} from 'components/customer/Product/InnerDrawer/helpers/calculateDrawerPositions';

interface CurrentInnerDrawerRunnerType {
    field: FieldType;
    fieldset: Fieldset;
    reducedLabelColumnWidth?: boolean;
}

export const CurrentInnerDrawerRunner = ({
    field,
    fieldset,
}: CurrentInnerDrawerRunnerType): JSX.Element => {
    const {
        drawerParams,
        values,
        setFieldValue,
        availableInnerDrawerSystems,
        runners,
        isLoading,
        isFetching,
    } = useCurrentInnerDrawer({
        field,
        fieldset,
    });

    const prevInnerDrawersRef = useRef<CurrentInnerDrawer[]>(
        values.current_inner_drawers || []
    );

    useEffect(() => {
        if (
            !isEqual(values.current_inner_drawers, prevInnerDrawersRef.current)
        ) {
            const calc = calculateDrawerPositions(
                drawerParams.totalAvailableHeight,
                values.current_inner_drawers
            );

            const updatedDrawers = values.current_inner_drawers.map(
                (drawer) => {
                    const pos = calc?.positions?.find(
                        (p) => p.index == drawer.index
                    );

                    return {
                        ...drawer,
                        position_to_bottom:
                            pos?.position_to_bottom ||
                            drawer.position_to_bottom,
                        isInvalid: pos?.isInvalid || false,
                        max_position: pos?.max_position || 0,
                        min_position: pos?.min_position || 0,
                        errorMessage: pos?.errorMessage || '',
                    };
                }
            );

            void setFieldValue('current_inner_drawers', updatedDrawers);

            prevInnerDrawersRef.current = updatedDrawers;
        }
    }, [values.current_inner_drawers]);

    return useMemo<JSX.Element>(() => {
        if (drawerParams) {
            return (
                <>
                    {values.current_inner_drawers.map((drawer, index) => (
                        <InnerDrawer
                            key={drawer.index}
                            runnerIndex={index}
                            drawerParams={drawerParams}
                            availableInnerDrawerSystems={
                                availableInnerDrawerSystems
                            }
                            runners={runners}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            showLine={
                                values.current_inner_drawers.length !==
                                index + 1
                            }
                        />
                    ))}
                </>
            );
        }

        return null;
    }, [drawerParams]);
};
